<template>
    <div>
        <app-header @getHeight="getHeight"></app-header>
        <div style="background-color: #fff">
            <div class="max_width min_width">
                <div class="flex align_center padtb_20" :style="{ 'margin-top': offsetHeight + 'px' }">
                    <div class="f838 size_14">当前位置：</div>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item class="" style="color: #ff6618">青海省中小企业数据库</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>
        <div style="background-color: #fff" >
            <div class="max_width min_width">
                <section class="bgfff">
                    <div style="
                width: 1480px;
                background-color:#FFFFFF;
  
                border-radius: 5px;
              ">

                        <!-- 搜索框 -->

                        <div class="flex align_center" style="justify-content: space-between">
                            <div class="flex align_center ">
                                <div class="fnf size_15 weight text_nowrap">关键词搜索：</div>
                                <div class="width_50 sear_search">
                                    <el-input placeholder="请输入关键词" style="width: 500px" v-model="keyword" @keyup.enter.native="search()">
                                        <i slot="suffix" class="el-icon-search" @click="search()"></i>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>


        <div style="background-color: #fff">
            <div class="max_width min_width">
                <section class="bgfff">
                    <div style="
                width: 1480px;
                border-radius: 5px;
              ">
                        <div>
                            <div class="flex align_start" style="padding: 10px 0px">
                                <div class="fnf size_16 weight marr_30 text_nowrap">
                                    省份地区：
                                </div>
                                <div class="text_line1" style="width: calc(100% - 75px)">
                                    <span class="fnf weight size_16 marr_30 laws_font"
                                        :class="{ laws_tag: addresss == '' }" @click="setTag('addresss')">全部</span>
                                    <span class="fnf size_16 marr_30 laws_font" v-for="(item, index) in address"
                                        :key="index" :class="{ laws_tag: addresss == item.name }"
                                        @click="setTag('addresss', item.name)">{{ item.name }}</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="flex align_start" style="padding: 10px 0px">
                                <div class="fnf size_16 weight marr_30 text_nowrap">
                                    所在城市：
                                </div>
                                <div class="text_line1" style="width: calc(100% - 75px)">
                                    <span class="fnf weight size_16 marr_30 laws_font"
                                        :class="{ laws_tag: citys == '' }" @click="setcityTag('citys')">全部</span>
                                    <span class="fnf size_16 marr_30 laws_font" v-for="(item, index) in city"
                                        :key="index" :class="{ laws_tag: citys == item.id }"
                                        @click="setcityTag('citys', item.id)">{{ item.name }}</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="flex align_start" style="padding: 10px 0px">
                                <div class="fnf size_16 weight marr_30 text_nowrap">
                                    企业类型：
                                </div>
                                <div class="text_line1"
                                    style="width: calc(100% - 75px);display: flex; flex-wrap: wrap;">
                                    <span class="fnf weight size_16 marr_30 laws_font"
                                        :class="{ laws_tag: types == '' }" @click="setTag('types')">全部</span>
                                    <span class="fnf size_16 marr_30 laws_font" v-for="(item, index) in type"
                                        :key="index" :class="{ laws_tag: types == item.name }"
                                        @click="setTag('types', item.name)">{{ item.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div>

                            <div class="flex align_start" style="padding: 10px 0px">
                                <div class="fnf size_16 weight marr_30 text_nowrap">
                                    企业状态：
                                </div>

                                <div class="text_line1" style="width: calc(100% - 75px)">
                                    <span class="fnf weight size_16 marr_30 laws_font"
                                        :class="{ laws_tag: stasuss == '' }" @click="setTag('stasuss')">全部</span>
                                    <span class="fnf size_16 marr_30 laws_font" v-for="(item, index) in stasus"
                                        :key="index" :class="{ laws_tag: stasuss == item.name }"
                                        @click="setTag('stasuss', item.name)">{{ item.name }}</span>
                                </div>
                            </div>
                        </div>


                    </div>

                </section>
            </div>
        </div>

        <div style="background-color: #fff">
            <div class="max_width min_width">
                <section class="bgfff" v-if="selectorder.length > 0">
                    <div style="width: 1480px;border-radius: 5px;justify-content: space-between;" class="flex"
                        v-for="(item, index) in selectorder" :key="index">
                        <div style=" flex: 1; border-bottom: 1px solid #E8E8E8;">
                            <div class="size_24 bold" style="padding-top: 30px;">
                                {{ item.name }}
                            </div>
                            <div class="flex" style="align-items: center;">
                                <div style="flex: 1;">
                                    <div class="flex" style="padding-top: 30px">
                                        <div style="" class="text size_18">
                                            法定代表人: {{ item.legalPersonName }}
                                        </div>
                                        <div class=" text size_18">
                                            注册资本：{{ item.regCapital }}
                                        </div>
                                        <div class="size_18 text">
                                            成立日期：{{ item.estiblishTime }}
                                        </div>

                                    </div>

                                    <div class="flex" style=" padding: 30px 0px;">
                                        <div class="size_18 text">
                                            企业状态：{{ item.regStatus }}
                                        </div>
                                        <div class="size_18 text" style="flex:1">
                                            地址：{{ item.regLocation }}
                                        </div>


                                    </div>
                                </div>

                                <div @click="gomessage(item.id)"
                                    style="width: 130px;height: 40px;background: #fff3eb;line-height: 40px;text-align: center;color:#ff6618;border-radius: 6px;">
                                    查看详情
                                </div>
                            </div>
                        </div>
                    </div>




                </section>
                <section class="bgfff" v-else>
                    <el-empty description="暂无数据,请搜索" :image-size="250"></el-empty>
                </section>
                
            </div>
        </div>
        <div>

        </div>

        <!-- 分页 -->
        <div v-if="selectorder.length > 0">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginations.page_index" :page-sizes="paginations.page_sizes"
                :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total">
            </el-pagination>
        </div>
        <app-footer></app-footer>
    </div>
</template>
  
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
    data() {
        return {
            allselectorder: [],
            selectorder: [],
            offsetHeight: 0,
            keyword: "",
            //省份地区
            address: [
                { id: 1, name: "青海省" },
            ],
            addresss: "",
            //城市
            citys: "",
            city: [],
            //企业类型
            type: [],
            types: "",
            //企业状态
            stasus: [
                { id: 1, name: "存续、在业" },
                { id: 2, name: "迁出" },
                { id: 3, name: "迁入" },
                { id: 4, name: "停业" },
                { id: 5, name: "已歇业" },
                { id: 6, name: "吊销" },
                { id: 7, name: "清算" },
                { id: 8, name: "撤销" },
                { id: 9, name: "注册" },
            ],
            stasuss: "",

            //分页
            paginations: {
                page_index: 1, // 当前位于哪页
                total: 10, // 总数
                page_size: 10, // 1页显示多少条
                page_sizes: [10, 20, 50, 90], //每页显示多少条
                layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
            },
        };
    },

    components: {
        "app-header": Header,
        "app-footer": Footer,
    },
    created() {
        this.getcity()
        this.info_lst()
        this.gettype()
    },

    methods: {

        search() {
            this.info_lst()
        },
        gomessage(item) {
            console.log(item, 'item')
            this.$router.push({ path: '/databasemessage', query: { id: item } })
        },
        getHeight(data) {
            console.log(data, "data");
            this.offsetHeight = data;
        },
        setTag(name, id = "") {
            
            this[name] = id;
            this.info_lst()
            //   this.selectlist();
        },
        setcityTag(name, id = "") {
            this[name] = id;
            this.info_lst()
            console.log("789");
            //   this.selectlist();
        },
        getcity() {
            let data = {
                token: localStorage.eleToken,
            };
            this.$get("area_select", data).then((res) => {
                console.log(res, "re789s");
                this.city = res.result.map((item) => {
                    return { id: item.id, name: item.name };
                });
                console.log(this.city, ' this.city ')
            });
        },
        gettype() {
            let data = {
                token: localStorage.eleToken,
            };
            this.$get("info_type_select", data).then((res) => {
                this.type = res.result.map((item) => {
                    return { id: item.id, name: item.name };
                });
                console.log(this.type, ' this.type ')
            });
        },

        info_lst(item) {
            let data = {
                keyword: this.keyword,
                city: this.citys,
                type: this.types,
                status: this.stasuss,
                page: item ? item : this.paginations.page_index,
                limit: this.paginations.page_size,
            };
            this.$post("info_lst", data).then((res) => {
                console.log(res, "re789s");
                this.allselectorder = res.result;
                if (res.count === "") {
                    this.paginations.total = 0;
                } else {
                    this.paginations.total = res.count;
                }

                //设置默认的分页数据
                if (this.allselectorder.length != 0) {
                    this.selectorder = this.allselectorder.filter((item, index) => {
                        return index < this.paginations.page_size;
                    });
                } else {
                    this.selectorder = [];
                }
                console.log(this.allselectorder, "this.allselectorder");


            });

        },
        // 分页
        handleSizeChange(page_size) {
            console.log(page_size, "page_size");
            this.paginations.page_index = 1;
            this.paginations.page_size = page_size;
            this.info_lst();
            this.selectorder = this.allselectorder.filter((item, index) => {
                return index < page_size;
            });
        },

        handleCurrentChange(page) {
            console.log(page, "handleCurrentChangepage");
            //获取当前页
            let index = this.paginations.page_size * (page - 1);
            //获取数据总数
            let nums = this.paginations.page_size * page;
            this.paginations.page_index = page
            this.info_lst(page);
            //容器
            let tables = [];
            for (let i = index; i < nums; i++) {
                if (this.allselectorder[i]) {
                    tables.push(this.allselectorder[i]);
                }

                this.selectorder = tables;
               
            }
        },





    },
};
</script>
<style scoped>
.sear_search {
    border-radius: 6px;
    margin-left: 12px;
}

.sear_search .el-icon-search {
    right: 10px;
    color: #ff7129 !important;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    top: 10px;
    position: relative;
}

.laws_tag {
    padding: 3px 12px;

    color: #ff7129;
    background: #FFDDCC;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
}

::v-deep.el-pagination {
    text-align: center;
    padding: 20px 0px;
    background-color: #fff;
}

.text {
    width: 33%;
    color: #595959;
}
</style>
  